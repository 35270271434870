import { useState } from 'react'
import cn from 'classnames'
import { LoadingDots } from '@components/ui'
import { motion } from 'framer-motion'

import s from './SolixButton.module.css'

const SolixButton = ({
  children,
  loading = false,
  animateKey,
  className,
  cns = {},
  style,
  border,
  theme,
  small,
  variant = 'button',
  variants = {},
  disabled = false,
  squareBorder = false,
  ...rest
}) => {
  const [onHover, setHover] = useState(false)

  const props = {
    key: animateKey || children,
    style: style || {},
    initial: 'initial',
    whileHover: 'hover',
    onHoverStart: () => {
      setHover(true)
    },
    onHoverEnd: () => {
      setHover(false)
    },
    disabled: disabled ?? loading,
    className: cn(
      s.root,
      className,
      disabled ? s?.disabled : '',
      squareBorder ? '!rounded-[0px]' : ''
    ),
    ...rest,
  }

  switch (variant) {
    case 'link':
      return (
        <motion.a {...props} key="SolixButton-link">
          <Inner loading={loading} cns={cns}>
            {children}
          </Inner>
        </motion.a>
      )
    default:
      return (
        <motion.button {...props} key="SolixButton-default">
          <Inner loading={loading} cns={cns}>
            {children}
          </Inner>
        </motion.button>
      )
  }
}

const Inner = ({ loading, cns, children }) => {
  return (
    <div className={cn(s.content, cns?.content)}>
      {loading ? (
        <div className="flex h-full w-full items-center justify-center">
          <LoadingDots />
        </div>
      ) : (
        children
      )}
    </div>
  )
}

export default SolixButton
